import { Controller } from "@hotwired/stimulus"
import getConsumer from "../helpers/consumer"

export default class extends Controller {
  static values = { room: Number }
  static targets = ["html"]

  connect() {
    const htmlTarget = this.htmlTarget

    if (!this.consumer) {
      this.consumer = getConsumer()
    }

    if (!this.devicePairingChannel) {
      this.devicePairingChannel = this.consumer.subscriptions.create({channel: "DevicePairingChannel", room: this.roomValue}, {
        connected: () => {
          const deviceInfo = this.deviceInformationFromWindow()
          this.devicePairingChannel.perform("update_device_information", deviceInfo)
        },
        received: (data) => {
          switch (data["status"]) {
            case "expired":
              const newHTML = data["html"]
              if (newHTML) {
                htmlTarget.outerHTML = data["html"]
              }
              break
            case "paired":
              window.location.reload()
              break
            default:
              // no-op
              break
          }
        }
      })
    }

    // this.deviceInformationFromWindow()
  }

  disconnect() {
    if (this.devicePairingChannel) {
      this.devicePairingChannel.unsubscribe()
    }
  }

  // Helpers below

  deviceInformationFromWindow() {
    const viewportResolution = `${screen.width} x ${screen.height}`
    const screenResolution = `${screen.width * devicePixelRatio} x ${screen.height * devicePixelRatio}`
    const commonDenominator = this.gcd(screen.width, screen.height)
    const aspectRatio = `${screen.width / commonDenominator}:${screen.height / commonDenominator}`

    const estimatedPPI = 96
    const diagonalPixels = Math.sqrt(Math.pow(screen.width, 2), Math.pow(screen.height, 2))

    const displayDimensions = `${screen.width / estimatedPPI} x ${screen.height / estimatedPPI}`
    const screenDiagonal = `${diagonalPixels / estimatedPPI}`

    return {
      viewport_resolution: viewportResolution,
      screen_resolution: screenResolution,
      pixel_ratio: devicePixelRatio,
      pixel_depth: screen.pixelDepth,
      color_depth: screen.colorDepth,
      aspect_ratio: aspectRatio,
      screen_size: screenDiagonal,
      display_dimensions: displayDimensions
    }
  }

  gcd(a, b) {
    // If it's 0, this is the gcd
    if (b == 0)
      return a

    // Continue to get the common denominator
    return this.gcd(b, a % b)
  }
}